import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Box,
  Switch,
  Image,
  Text,
  Flex,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

export interface IItemDND {
  id: string;
  text: string;
  checked: boolean;
}

interface IDragAndDrop {
  items: IItemDND[];
  setItems: React.Dispatch<React.SetStateAction<IItemDND[]>>;
  refreshItems: boolean;
  setRefreshItems: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldUpdateItems: React.Dispatch<React.SetStateAction<boolean>>;
  disableDragAndDrop?: boolean;
}

const ColumnsDragAndDrop = (props: IDragAndDrop) => {
  const [itemsActive, setItemsActive] = useState<IItemDND[]>([]);
  const [itemsNotActive, setItemsNotActive] = useState<IItemDND[]>([]);

  useEffect(() => {
    if (props.refreshItems) {
      setItemsActive(props.items.filter((i) => i.checked));
      setItemsNotActive(props.items.filter((i) => !i.checked));
      props.setRefreshItems(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refreshItems, props.items]);

  useEffect(() => {
    props.setItems([...itemsActive, ...itemsNotActive]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsActive, itemsNotActive]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || props.disableDragAndDrop) return;

    const newItemsActive = Array.from(itemsActive);
    const [movedItem] = newItemsActive.splice(result.source.index, 1);
    newItemsActive.splice(result.destination.index, 0, movedItem);

    setItemsActive(newItemsActive);
    props.setShouldUpdateItems(true);

  };

  const toggleItem = (id: string) => {
    let updatedActive = [...itemsActive];
    let updatedNotActive = [...itemsNotActive];

    if (itemsActive.some((item) => item.id === id)) {
      const toggledItem = updatedActive.find((item) => item.id === id);
      updatedActive = updatedActive.filter((item) => item.id !== id);
      if (toggledItem)
        updatedNotActive.push({ ...toggledItem, checked: false });
    } else {
      const toggledItem = updatedNotActive.find((item) => item.id === id);
      updatedNotActive = updatedNotActive.filter((item) => item.id !== id);
      if (toggledItem) updatedActive.push({ ...toggledItem, checked: true });
    }

    setItemsActive(updatedActive);
    setItemsNotActive(updatedNotActive);
    props.setShouldUpdateItems(true);
  };

  const handleShowAll = () => {
    const updatedActive = [...itemsActive];
    const updatedNotActive = [...itemsNotActive];

    updatedNotActive.forEach((item) => {
      updatedActive.push({ ...item, checked: true });
    });

    setItemsActive(updatedActive);
    setItemsNotActive([]);
    props.setShouldUpdateItems(true);
  };

  return (
    <ChakraProvider>
      <Text>Exibir colunas</Text>
      <Flex my={"8px"} justifyContent={"space-between"}>
        <Text fontSize={"12px"} fontWeight={"400"}>
          Exibidas
        </Text>

        <Link
          display={"flex"}
          color={"#4B4EFF"}
          fontSize={"12px"}
          onClick={handleShowAll}
        >
          <Text>Mostrar tudo</Text>
        </Link>
      </Flex>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="items" isDropDisabled={props.disableDragAndDrop}>
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              overflow="hidden"
            >
              {itemsActive.map((item, index) => (
                <Draggable
                  isDragDisabled={props.disableDragAndDrop || !item.checked}
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p="2"
                      bg="white"
                      _hover={{ bg: "gray.100" }}
                    >
                      <Box display={"flex"}>
                        {props.disableDragAndDrop ? null : (
                          <Image
                            width={"6px"}
                            src="/icons/droppable.svg"
                            alt="droppable"
                            marginRight={"6px"}
                          />
                        )}
                        {item.text}
                      </Box>
                      <Tooltip
                        label={"Você precisa deixar pelo menos 1 coluna ativa"}
                        isDisabled={itemsActive.length !== 1}
                        placement="top"
                      >
                        <span>
                          <Switch
                            isChecked={item.checked}
                            isDisabled={itemsActive.length === 1}
                            onChange={() => toggleItem(item.id)}
                            sx={{
                              "& .chakra-switch__track": {
                                backgroundColor: item.checked
                                  ? "#4B4EFF"
                                  : "#e2e8f0",
                              },
                            }}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Box overflow="hidden">
          <Text fontSize={"12px"} fontWeight={"400"} my={"8px"}>
            Ocultas
          </Text>
          {itemsNotActive.map((item) => (
            <Box
              key={item.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p="2"
              bg="gray.50"
            >
              <Box>{item.text}</Box>
              <Switch
                isChecked={item.checked}
                onChange={() => toggleItem(item.id)}
                colorScheme="teal"
              />
            </Box>
          ))}
        </Box>
      </DragDropContext>
    </ChakraProvider>
  );
};

export default ColumnsDragAndDrop;
