import { Flex, Image, Text, Tooltip } from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import { useCookies } from "react-cookie";
import { DragIcon } from "../icons/Drag";
import { IWidgetBase } from "../interfaces/IWidget";

export const WidgetBase = (props: IWidgetBase) => {
  const [cookies, setCookie] = useCookies(["dashboard"]);

  return (
    <Draggable key={props.id} draggableId={props.id} index={props.index}>
      {(provided) => (
        <Flex bgColor="white" flexDirection="column" boxShadow="1px 1px 5px #b7b4b4" borderRadius={4} overflow="hidden" {...provided.draggableProps} ref={provided.innerRef} >
          <Flex bgColor="gray.50" p="14px" borderBottom="1px solid" borderColor="gray.300" alignItems="center" zIndex={10} justifyContent="space-between" {...provided.dragHandleProps} overflow='hidden'>
            <Flex alignItems="center" gap={2} overflow='hidden' justifyContent="space-between" w="100%">
              <Flex>
                <DragIcon boxSize={6} color="gray.300" overflow='hidden'/>
                <Text fontWeight="bold" color="gray.700">{props.title}</Text>
              </Flex>

              <Flex>
                {
                  props.exportFunction ? (
                    <Flex cursor="pointer" p="5px" onClick={props.exportFunction}>
                      <Image w="15px" src="/icons/download-card-file.svg"/>
                    </Flex>
                  ) : null
                }
              </Flex>
            </Flex>
          </Flex>
          <Flex p={props.p ? props.p : 4} position="relative">
            {props.isLoading ? (
              <Flex bg="whiteAlpha.800" position="absolute" top={0} bottom={0} left={0} right={0} zIndex={997} alignItems="center" justifyContent="center" fontWeight="bold" color="#4B4EFF">
                Carregando Dados...
              </Flex>
            ) : null}
            {props.children}
          </Flex>
        </Flex>
      )}
    </Draggable>
  );
}